import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { LOAD_ERROR_MESSAGE } from '@engined/client/locales.js';
import { getLogger } from '@engined/core/services/logger.js';
import { Alert, Box, CircularProgress, Fade } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect } from 'react';

const logger = getLogger('components/Loading');

interface OwnProps {
  error?: any;
  sx?: SxProps;
  size?: string;
}

const Loading: React.FunctionComponent<OwnProps> = ({ error, size, sx }) => {
  const { t } = useLocale();

  useEffect(() => {
    if (error && !error.message?.includes('Load failed') && !error.message?.includes('Failed to fetch')) {
      logger.error(error);
    }
  }, [error]);

  if (error) {
    return <Alert severity="error">{t(LOAD_ERROR_MESSAGE)}</Alert>;
  }

  return (
    <Box sx={sx} display="flex" alignItems="center">
      <Fade
        in
        style={{
          transitionDelay: '300ms',
        }}
        unmountOnExit
      >
        <CircularProgress size={size} />
      </Fade>
    </Box>
  );
};

export default Loading;
